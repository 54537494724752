<script lang="ts">
import AuthFooter from '@/components/AuthFooter'

export default {
  name: 'CompanyDeleted',
  components: { AuthFooter },

  layout: 'DefaultLayout',
}
</script>

<template>
  <div
    class="tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-gray-200 tw-p-4 ie_tw-h-screen"
  >
    <div class="tw-w-full w-360">
      <div class="tw-px-8 tw-py-6 tw-bg-white tw-rounded tw-shadow-md">
        <div class="tw-flex tw-flex-row">
          <div class="tw-mr-6 tw-flex tw-items-center">
            <SvgIcon
              name="icon-check-circle"
              class="tw-w-12 tw-h-12 tw-ml-2 tw-text-green-500"
            />
          </div>
          <div>
            <div class="tw-mb-3 tw-font-medium tw-text-xl">
              Company deleted successfully
            </div>
            <div class="tw-text-base tw-text-gray-700">
              Thank you for using leavedates.
            </div>
          </div>
        </div>
      </div>

      <AuthFooter />
    </div>
  </div>
</template>
