<template>
  <div id="recaptcha"></div>
</template>

<script>
export default {
  name: 'ReCaptcha',

  props: {
    callback: {
      type: Function,
      required: true,
    },
  },

  mounted() {
    if (typeof window.grecaptcha === 'undefined') {
      window.grecaptcha = {}
      window.grecaptcha.ready = function(cb) {
        const c = '___grecaptcha_cfg'
        window[c] = window[c] ? window[c] : {}
        window[c]['fns'] = window[c]['fns'] ? window[c]['fns'] : []
        window[c]['fns'].push(cb)
      }
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha.render('recaptcha', {
        sitekey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
        size: 'invisible',
        callback: this.handleToken,
      })
    })
  },

  methods: {
    execute() {
      window.grecaptcha.execute()
    },
    handleToken(token) {
      this.callback(token)

      window.grecaptcha.reset()
    },
  },
}
</script>
