<template>
  <tr>
    <td>{{ webhook.url }}</td>

    <td style="width: 30%;">
      <template v-if="hasWebhookSecretRevealed">
        {{ webhook.secret }}
      </template>

      <a v-else class="btn-link" @click="revealWebhookSecret">
        Reveal
      </a>
    </td>

    <td>
      <div class="tw-flex tw-items-center">
        <template>
          {{ webhook.event_types.length }}
          {{ 'event' | pluralize(webhook.event_types.length) }}
        </template>

        <ExtraInfo icon="question">
          <div class="tw-p-4 tw-w-48">
            <p v-for="event in webhook.event_types" :key="event">{{ event }}</p>
          </div>
        </ExtraInfo>
      </div>
    </td>

    <td style="width: 200px;">
      <BaseTag :theme="webhookStatusColor">{{ webhook.status }}</BaseTag>
    </td>

    <td style="width: 100px;">
      <div class="tw-flex tw-items-center tw-justify-center">
        <IconButton
          icon="eye"
          title="View"
          @click="$emit('show-call-events')"
        />

        <UpgradePlanPopup
          action-message="edit webhook"
          :feature="Feature.Webhooks"
          :is-active="!companyHasWebhooksFeature"
        >
          <IconButton
            class="tw-ml-3"
            icon="edit-pencil"
            title="Edit"
            :disabled="!companyHasWebhooksFeature"
            @click="$emit('edit')"
          />
        </UpgradePlanPopup>

        <IconButton
          class="tw-ml-3"
          theme="red"
          icon="trash"
          title="Delete"
          @click="$emit('delete')"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import BaseTag from '@/components/BaseTag'
import ExtraInfo from '@/components/ExtraInfo'
import IconButton from '@/components/IconButton'
import UpgradePlanPopup from '@/components/UpgradePlanPopup'
import Feature from '@/models/Billing/Feature'

export default {
  name: 'WebhooksTableRow',

  components: {
    UpgradePlanPopup,
    BaseTag,
    ExtraInfo,
    IconButton,
  },

  props: {
    webhook: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hasWebhookSecretRevealed: false,
    }
  },

  computed: {
    webhookStatusColor() {
      return this.webhook.status === 'Active' ? 'green' : 'red'
    },
    Feature() {
      return Feature
    },
    companyHasWebhooksFeature() {
      return this.activeCompany.hasFeature(Feature.Webhooks)
    },
  },

  methods: {
    revealWebhookSecret() {
      this.hasWebhookSecretRevealed = true
    },
  },
}
</script>
