<template>
  <div>
    <div class="tw--mx-2 tw-pt-4 tw-flex sm:tw-flex-row-reverse tw-flex-wrap">
      <div class="tw-px-2 tw-w-full md:tw-w-1/3 xl:tw-w-1/5">
        <div class="card">
          <div class="tw-mb-8 tw-text-2xl tw-font-semibold">
            Filters
          </div>
          <div class="sm:tw-flex md:tw-block sm:tw--mx-2 md:tw-mx-0">
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group">
                <label class="form-label" for="from_date">From</label>
                <SingleDatePicker
                  id="from_date"
                  v-model="reportData.fromDate"
                  :format="dateFormatWithSlashByLocale"
                  :placeholder="dateFormatWithSlashByLocale"
                  data-vv-as="from date"
                  data-cy="holiday-from-date"
                  name="from_date"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                  readonly
                  emits-moment
                  @input="fetchCompanyHolidayNames"
                />
              </div>
              <div class="form-group">
                <label class="form-label" for="to_date">To</label>
                <SingleDatePicker
                  id="to_date"
                  v-model="reportData.toDate"
                  :format="dateFormatWithSlashByLocale"
                  :placeholder="dateFormatWithSlashByLocale"
                  data-vv-as="to date"
                  data-cy="holiday-to-date"
                  name="to_date"
                  class="form-control"
                  autocomplete="off"
                  type="text"
                  readonly
                  emits-moment
                  @input="fetchCompanyHolidayNames"
                />
              </div>
              <div class="form-group">
                <label class="form-label" for="holiday_name"
                  >Public Holiday</label
                >
                <div class="tw-relative tw-w-full">
                  <HolidayNamePicker
                    id="holiday_name"
                    v-model="reportData.selectedHolidayName"
                    :options="selectableHolidayNames"
                  />
                </div>
              </div>
            </div>
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group">
                <label class="form-label" for="department">Department</label>
                <div class="tw-relative tw-w-full">
                  <DepartmentPicker
                    id="department"
                    v-model="reportData.selectedDepartment"
                    :options="selectableDepartments"
                    @input="getEmployments"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="employment">Employee</label>
                <div class="tw-relative tw-w-full">
                  <EmploymentPicker
                    id="employment"
                    v-model="reportData.selectedEmployment"
                    :options="selectableEmployments"
                  />
                </div>
              </div>
              <div class="tw-flex tw-justify-start">
                <UpgradePlanPopup
                  action-message="download report"
                  :feature="Feature.ExportToExcel"
                  :is-active="!companyHasExportToExcelFeature"
                >
                  <SpinnerButton
                    :disabled="
                      !companyHasExportToExcelFeature || downloadingHoliday
                    "
                    :loading="downloadingHoliday"
                    :spinner-only="true"
                    class="tw-mt-6"
                    data-cy="download-xl"
                    @click="downloadEmploymentHolidaysReport"
                  >
                    Download
                  </SpinnerButton>
                </UpgradePlanPopup>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-px-2 tw-w-full md:tw-w-2/3 xl:tw-w-4/5">
        <div class="card">
          <EmploymentHolidaysReportTable
            :employment-holidays="paginatedEmploymentHolidays.data"
            :loading="fetchingHoliday"
          />
        </div>

        <div class="paginate-wrapper">
          <Pagination
            :current-page="paginatedEmploymentHolidays.current_page"
            :page-count="pageCount"
            :click-handler="fetchEmploymentHolidaysReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import moment from 'moment-timezone'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import SingleDatePicker from '@/components/SingleDatePicker'
import HandleReportData from '@/mixins/HandleReportData'
import Pagination from '@/components/pagination/Pagination'
import DepartmentPicker from '@/components/pickers/DepartmentPicker'
import EmploymentPicker from '@/components/pickers/EmploymentPicker'
import HolidayNamePicker from '@/components/pickers/HolidayNamePicker'
import EmploymentHolidaysReportTable from '@/components/reports/employment-holidays/EmploymentHolidaysReportTable'
import SpinnerButton from '@/components/SpinnerButton'
import UpgradePlanPopup from '@/components/UpgradePlanPopup'
import Feature from '@/models/Billing/Feature'
import EmploymentHolidays from '@/api/reporting/EmploymentHolidays'
import { Employments } from '@/api'
import CompanyHolidays from '@/api/reporting/CompanyHolidays'

const PAGINATED_EMPLOYMENT_HOLIDAY = {
  data: [],
  total: 0,
  per_page: 0,
  current_page: 1,
}

export default {
  name: 'EmploymentHolidaysReport',

  components: {
    UpgradePlanPopup,
    Pagination,
    SingleDatePicker,
    DepartmentPicker,
    EmploymentPicker,
    HolidayNamePicker,
    EmploymentHolidaysReportTable,
    SpinnerButton,
  },

  mixins: [FormatDate, HandleReportData, ValidatesForm],

  data() {
    return {
      holidayNames: [],
      departments: [],
      employments: [],
      reportData: {
        selectedDepartment: '',
        selectedEmployment: '',
        selectedHolidayName: '',
        toDate: moment(),
        fromDate: moment().startOf('month'),
      },
      fetchingHoliday: false,
      downloadingHoliday: false,
      paginatedEmploymentHolidays: PAGINATED_EMPLOYMENT_HOLIDAY,
    }
  },

  computed: {
    Feature() {
      return Feature
    },

    companyHasExportToExcelFeature() {
      return this.activeCompany.hasFeature(Feature.ExportToExcel)
    },

    pageCount() {
      return Math.ceil(
        this.paginatedEmploymentHolidays.total /
          this.paginatedEmploymentHolidays.per_page
      )
    },

    dateFormatWithSlashByLocale() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
    },

    selectableHolidayNames() {
      return [
        this.allOption,
        ...this.holidayNames.map(name => ({
          id: name,
          name: name,
        })),
      ]
    },

    selectableEmployments() {
      return [this.allOption, ...this.employments]
    },
  },

  watch: {
    reportData: {
      deep: true,
      handler() {
        this.paginatedEmploymentHolidays = PAGINATED_EMPLOYMENT_HOLIDAY
        this.fetchEmploymentHolidaysReport()
      },
    },

    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.holiday = []
        this.reportData = {
          selectedDepartment: this.allOption,
          selectedEmployment: this.allOption,
          selectedHolidayName: this.allOption,
          toDate: moment(),
          fromDate: moment().startOf('month'),
        }

        this.fetchCompanyHolidayNames()
        this.fetchDepartments()
        this.getEmployments()
      },
    },
  },

  methods: {
    async fetchCompanyHolidayNames() {
      try {
        const { data } = await CompanyHolidays.getHolidayNames({
          company_id: this.activeCompany.id,
          within:
            this.reportData.fromDate.startOf('day').format() +
            ',' +
            this.reportData.toDate.endOf('day').format(),
        })

        this.holidayNames = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    getEmployments() {
      this.reportData.selectedEmployment = this.allOption

      if (this.isAdmin) this.fetchEmployments()
      if (this.isNonAdminApprover) this.getSubordinates()
    },

    async fetchEmployments() {
      try {
        const { data } = await Employments.find({
          company_id: this.activeCompany.id,
          department: this.reportData.selectedDepartment.id,
        })

        this.employments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchEmploymentHolidaysReport(page) {
      this.fetchingHoliday = true

      try {
        const { data } = await EmploymentHolidays.getReport({
          company_id: this.activeCompany.id,
          'holiday-name': this.reportData.selectedHolidayName.id,
          employee: this.reportData.selectedEmployment.id,
          department: this.reportData.selectedDepartment.id,
          within:
            this.reportData.fromDate.startOf('day').format() +
            ',' +
            this.reportData.toDate.endOf('day').format(),
          page: page || this.paginatedEmploymentHolidays.current_page,
        })

        this.paginatedEmploymentHolidays = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.fetchingHoliday = false
    },

    async downloadEmploymentHolidaysReport() {
      if (!this.companyHasExportToExcelFeature) {
        return
      }

      this.downloadingHoliday = true

      const fromDate = this.reportData.fromDate.startOf('day')
      const toDate = this.reportData.toDate.endOf('day')

      try {
        const { data } = await EmploymentHolidays.downloadReport({
          company_id: this.activeCompany.id,
          'holiday-name': this.reportData.selectedHolidayName.id,
          employee: this.reportData.selectedEmployment.id,
          department: this.reportData.selectedDepartment.id,
          within: fromDate.format() + ',' + toDate.format(),
        })

        const fileName = `public-holiday-report-${fromDate.format(
          'YYYY-MM-DD'
        )}-${toDate.format('YYYY-MM-DD')}.xlsx`

        FileSaver.saveAs(new Blob([data]), fileName)
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.downloadingHoliday = false
    },
  },
}
</script>

<style>
.paginate-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
