<template>
  <div class="tw-flex tw-items-center">
    <div
      class="tw-px-3 tw-py-2 tw-space-x-2 tw-border tw-border-gray-300 tw-flex tw-items-center tw-bg-white tw-rounded-lg"
    >
      <div
        class="tw-px-3 tw-py-2 tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-between tw-bg-gray-200 tw-rounded-full"
      >
        <SvgIcon :name="fileIcon" class="tw-text-gray-800 tw-w-8 tw-h-8" />
      </div>

      <span
        class="tw-font-medium"
        :class="{ 'tw-line-through': event.isDeleted() }"
      >
        {{ attachment.name }}
      </span>
    </div>
    <span v-if="event.isDeleted()" class="tw-ml-4 tw-text-gray-600"
      >{{ event.getDeleterName(activeEmployment) }} deleted this
      attachment</span
    >
  </div>
</template>

<script>
import prettyFileIcons from 'pretty-file-icons'

export default {
  name: 'AttachmentPreview',

  props: {
    attachment: {
      type: Object,
      required: true,
    },

    event: {
      type: Object,
      required: true,
    },
  },

  computed: {
    fileIcon() {
      return prettyFileIcons.getIcon(this.attachment.name)
    },
  },
}
</script>
