import { groupBy } from 'lodash-es'
import moment from 'moment/moment'

export default class EmploymentSchedule {
  id = null
  dateOfBirth = null
  leave = []
  overtime = []
  holidays = []
  leaveLimits = []

  constructor(rowEmploymentSchedule = null) {
    if (rowEmploymentSchedule) {
      this.id = rowEmploymentSchedule.id
      this.dateOfBirth = rowEmploymentSchedule.dateOfBirth
        ? moment.utc(rowEmploymentSchedule.dateOfBirth)
        : null

      this.leave = rowEmploymentSchedule.leave.map(leave => {
        return {
          ...leave,
          start: moment.utc(leave.start).tz(leave.timezone),
          end: moment.utc(leave.end).tz(leave.timezone),
        }
      })

      this.overtime = rowEmploymentSchedule.overtime.map(overtime => {
        return {
          ...overtime,
          start: moment.utc(overtime.start).tz(overtime.timezone),
          end: moment.utc(overtime.end).tz(overtime.timezone),
        }
      })

      this.holidays = rowEmploymentSchedule.holidays.map(holiday => {
        return {
          ...holiday,
          date: moment.utc(holiday.date),
        }
      })

      this.leaveLimits = rowEmploymentSchedule.leaveLimits.map(limit => {
        return {
          start: moment.utc(limit.start),
          end: moment.utc(limit.end),
        }
      })
    }
  }

  findLeaveLimits(period) {
    return this.leaveLimits.filter(
      limit =>
        limit.end.isSameOrAfter(period.start) &&
        limit.start.isSameOrBefore(period.end)
    )
  }

  isBirthdayWithinPeriod(period) {
    return (
      this.dateOfBirth?.isSameOrAfter(period.start) &&
      this.dateOfBirth?.isSameOrBefore(period.end)
    )
  }

  findGroupedHolidays(period) {
    let holidays = this.holidays.filter(
      holiday =>
        holiday.date.isSameOrAfter(period.start) &&
        holiday.date.isSameOrBefore(period.end)
    )

    return groupBy(holidays, holiday => {
      return holiday.date.format('YYYY-MM-DD') + holiday.type
    })
  }

  findLeave(period) {
    return this._findRequestsWithinPeriod(this.leave, period)
  }

  findOvertime(period) {
    return this._findRequestsWithinPeriod(this.overtime, period)
  }

  _findRequestsWithinPeriod(requests, period) {
    return requests.filter(request =>
      this._isRequestWithinPeriod(request, period)
    )
  }

  _isRequestWithinPeriod(request, period) {
    return (
      request.start.isSameOrBefore(moment.tz(period.end, request.timezone)) &&
      request.end.isSameOrAfter(moment.tz(period.start, request.timezone))
    )
  }
}
