<template>
  <div>
    <div class="tw--mx-2 tw-pt-4 tw-flex sm:tw-flex-row-reverse tw-flex-wrap">
      <div class="tw-px-2 tw-w-full md:tw-w-1/3 xl:tw-w-1/5">
        <div class="card">
          <div class="tw-mb-8 tw-text-2xl tw-font-semibold">
            Filters
          </div>
          <div class="sm:tw-flex md:tw-block sm:tw--mx-2 md:tw-mx-0">
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group tw-mb-0">
                <label class="form-label" for="department">Department</label>
                <div class="tw-relative tw-w-full">
                  <DepartmentPicker
                    id="department"
                    v-model="reportData.selectedDepartment"
                    :options="selectableDepartments"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-justify-start">
            <UpgradePlanPopup
              action-message="download report"
              :feature="Feature.ExportToExcel"
              :is-active="!companyHasExportToExcelFeature"
            >
              <SpinnerButton
                :disabled="
                  !companyHasExportToExcelFeature || downloadingEmployeeData
                "
                :loading="downloadingEmployeeData"
                :spinner-only="true"
                class="tw-mt-6"
                data-cy="download-xl"
                @click="downloadEmployeeReport"
              >
                Download
              </SpinnerButton>
            </UpgradePlanPopup>
          </div>
        </div>
      </div>

      <div class="tw-px-2 tw-w-full md:tw-w-2/3 xl:tw-w-4/5">
        <div class="card">
          <EmploymentReportsTable
            :employments="paginatedEmployments.data"
            :loading="fetchingEmployeeData"
          />
        </div>

        <div class="paginate-wrapper">
          <Pagination
            :current-page="paginatedEmployments.current_page"
            :page-count="pageCount"
            :click-handler="fetchEmployeeReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import moment from 'moment-timezone'
import ValidatesForm from '@/mixins/ValidatesForm'
import HandleReportData from '@/mixins/HandleReportData'
import Pagination from '@/components/pagination/Pagination'
import DepartmentPicker from '@/components/pickers/DepartmentPicker'
import EmploymentReportsTable from '@/components/reports/employments/EmploymentReportsTable'
import SpinnerButton from '@/components/SpinnerButton'
import Feature from '@/models/Billing/Feature'
import UpgradePlanPopup from '@/components/UpgradePlanPopup'
import { Employments } from '@/api/reporting/Employments'

const PAGINATED_EMPLOYMENT = {
  data: [],
  total: 0,
  per_page: 0,
  current_page: 1,
}

export default {
  name: 'EmploymentsReport',

  components: {
    UpgradePlanPopup,
    Pagination,
    DepartmentPicker,
    EmploymentReportsTable,
    SpinnerButton,
  },

  mixins: [HandleReportData, ValidatesForm],

  data() {
    return {
      fetchingEmployeeData: false,
      downloadingEmployeeData: false,
      reportData: {
        selectedDepartment: '',
      },
      departments: [],
      paginatedEmployments: PAGINATED_EMPLOYMENT,
    }
  },

  computed: {
    Feature() {
      return Feature
    },

    companyHasExportToExcelFeature() {
      return this.activeCompany.hasFeature(Feature.ExportToExcel)
    },

    pageCount() {
      return Math.ceil(
        this.paginatedEmployments.total / this.paginatedEmployments.per_page
      )
    },
  },

  watch: {
    reportData: {
      deep: true,
      handler() {
        this.paginatedEmployments = PAGINATED_EMPLOYMENT
        this.fetchEmployeeReport()
      },
    },

    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.reportData = {
          selectedDepartment: this.allOption,
        }

        this.fetchDepartments()
      },
    },
  },

  methods: {
    async fetchEmployeeReport(page) {
      this.fetchingEmployeeData = true

      try {
        const { data } = await Employments.getReport({
          page: page || this.paginatedEmployments.current_page,
          company_id: this.activeCompany.id,
          department: this.reportData.selectedDepartment.id,
        })

        this.paginatedEmployments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.fetchingEmployeeData = false
    },

    async downloadEmployeeReport() {
      if (!this.companyHasExportToExcelFeature) {
        return
      }

      this.downloadingEmployeeData = true

      try {
        const date = moment().format('YYYY-MM-DD')

        const { data } = await Employments.downloadReport({
          date: date,
          company_id: this.activeCompany.id,
          department: this.reportData.selectedDepartment.id,
        })

        FileSaver.saveAs(
          new Blob([data]),
          'employments-report-' + date + '.xlsx'
        )
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.downloadingEmployeeData = false
    },
  },
}
</script>

<style>
.paginate-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
