<template>
  <div>
    <div class="tw--mx-2 tw-pt-4 tw-flex sm:tw-flex-row-reverse tw-flex-wrap">
      <div class="tw-px-2 tw-w-full md:tw-w-1/3 xl:tw-w-1/5">
        <div class="card" data-cy="filters-card">
          <div class="tw-mb-8 tw-text-2xl tw-font-semibold">
            Filters
          </div>
          <div class="sm:tw-flex md:tw-block sm:tw--mx-2 md:tw-mx-0">
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group">
                <label class="form-label" for="report_date">Report Date</label>
                <SingleDatePicker
                  id="report_date"
                  v-model="reportData.selectedDate"
                  :format="dateFormatWithSlashByLocale"
                  :placeholder="dateFormatWithSlashByLocale"
                  data-vv-as="report date"
                  data-cy="report-date"
                  name="report_date"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                  readonly
                  emits-moment
                />
              </div>
              <div class="form-group">
                <label class="form-label" for="allowances">Allowances</label>
                <div class="tw-relative tw-w-full">
                  <vSelect
                    id="allowances"
                    v-model="allowanceType"
                    :options="selectableAllowanceTypes"
                    :multiple="false"
                    :searchable="false"
                    :show-labels="false"
                    :allow-empty="false"
                    :max-height="180"
                    track-by="id"
                    label="name"
                    placeholder=""
                  >
                  </vSelect>
                </div>
              </div>
            </div>
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group">
                <label class="form-label" for="department">Department</label>
                <div class="tw-relative tw-w-full">
                  <DepartmentPicker
                    id="department"
                    v-model="reportData.selectedDepartment"
                    :options="selectableDepartments"
                    @input="getEmployments"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="employment">Employee</label>
                <div class="tw-relative tw-w-full">
                  <EmploymentPicker
                    id="employment"
                    v-model="reportData.selectedEmployment"
                    :options="selectableEmployments"
                  />
                </div>
              </div>
              <div class="form-group tw-mb-0">
                <label class="form-label" for="report-type">Report Type</label>
                <div class="tw-relative tw-w-full">
                  <ReportTypePicker
                    id="report-type"
                    v-model="reportData.reportType"
                    :options="selectableReportTypes"
                    :reduce="option => option.id"
                  />
                </div>
              </div>
              <div class="tw-flex tw-justify-start">
                <UpgradePlanPopup
                  action-message="download report"
                  :feature="Feature.ExportToExcel"
                  :is-active="!companyHasExportToExcelFeature"
                >
                  <SpinnerButton
                    :disabled="
                      !companyHasExportToExcelFeature || downloadingAllowance
                    "
                    :loading="downloadingAllowance"
                    :spinner-only="true"
                    class="tw-mt-6"
                    data-cy="download-xl"
                    @click="downloadAllowanceReport"
                  >
                    Download
                  </SpinnerButton>
                </UpgradePlanPopup>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-px-2 tw-w-full md:tw-w-2/3 xl:tw-w-4/5">
        <div class="card">
          <div v-if="reportData.reportType === 'detail-report'">
            <AllowanceDetailReportTable
              :loading="fetchingAllowance"
              :employment-allowances="paginatedAllowances.data"
            />
          </div>
          <div v-if="reportData.reportType === 'summary-report'">
            <AllowanceSummaryReportTable
              :employment-allowance-summaries="paginatedAllowances.data"
              :loading="fetchingAllowance"
            />
          </div>
        </div>

        <div class="paginate-wrapper">
          <Pagination
            :current-page="paginatedAllowances.current_page"
            :page-count="pageCount"
            :click-handler="fetchAllowances"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash-es'
import moment from 'moment-timezone'
import vSelect from 'vue-multiselect'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import HandleReportData from '@/mixins/HandleReportData'
import Pagination from '@/components/pagination/Pagination'
import DepartmentPicker from '@/components/pickers/DepartmentPicker'
import EmploymentPicker from '@/components/pickers/EmploymentPicker'
import ReportTypePicker from '@/components/pickers/ReportTypePicker'
import AllowanceDetailReportTable from '@/components/reports/allowances/AllowanceDetailReportTable'
import AllowanceSummaryReportTable from '@/components/reports/allowances/AllowanceSummaryReportTable'
import SpinnerButton from '@/components/SpinnerButton'
import { AllowanceReport, AllowanceTypes, Employments } from '@/api'
import EmploymentAllowanceCollection from '@/models/reporting/EmploymentAllowanceCollection'
import UpgradePlanPopup from '@/components/UpgradePlanPopup'
import Feature from '@/models/Billing/Feature'

const SingleDatePicker = () => import('@/components/SingleDatePicker')

export default {
  name: 'AllowancesReport',

  components: {
    UpgradePlanPopup,
    vSelect,
    SingleDatePicker,
    ReportTypePicker,
    EmploymentPicker,
    DepartmentPicker,
    AllowanceDetailReportTable,
    AllowanceSummaryReportTable,
    Pagination,
    SpinnerButton,
  },

  mixins: [FormatDate, HandleReportData, ValidatesForm],

  data() {
    return {
      fetchingAllowance: true,
      downloadingAllowance: false,
      allowanceTypes: [],
      departments: [],
      employments: [],
      companyAllowanceSummaries: [],
      reportData: {
        reportType: 'detail-report',
        selectedAllowanceType: '',
        selectedDepartment: '',
        selectedEmployment: '',
        selectedDate: moment(),
      },
      paginatedAllowances: {
        data: new EmploymentAllowanceCollection([]),
        total: 0,
        per_page: 0,
        current_page: 1,
      },
    }
  },

  computed: {
    Feature() {
      return Feature
    },

    companyHasExportToExcelFeature() {
      return this.activeCompany.hasFeature(Feature.ExportToExcel)
    },

    pageCount() {
      return Math.ceil(
        this.paginatedAllowances.total / this.paginatedAllowances.per_page
      )
    },

    dateFormatWithSlashByLocale() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
    },

    selectableAllowanceTypes() {
      return [this.allOption, ...this.allowanceTypes]
    },

    selectableEmployments() {
      return [this.allOption, ...this.employments]
    },

    allowanceType: {
      get() {
        return find(this.selectableAllowanceTypes, {
          id: this.reportData.selectedAllowanceType.id,
        })
      },
      set(value) {
        this.reportData.selectedAllowanceType = value
      },
    },

    selectableReportTypes() {
      return [
        {
          id: 'detail-report',
          name: 'Detail',
        },
        {
          id: 'summary-report',
          name: 'Summary',
        },
      ]
    },
  },

  watch: {
    reportData: {
      deep: true,
      handler() {
        this.resetPagination()
        this.fetchAllowances()
      },
    },

    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.reportData = {
          reportType: 'detail-report',
          selectedDate: moment(),
          selectedDepartment: this.allOption,
          selectedEmployment: this.allOption,
          selectedAllowanceType: this.allOption,
        }

        this.fetchAllowanceTypes()
        this.fetchDepartments()
        this.getEmployments()
      },
    },
  },

  methods: {
    resetPagination() {
      const allowances =
        this.reportData.reportType === 'detail-report'
          ? new EmploymentAllowanceCollection([])
          : []

      this.paginatedAllowances = {
        total: 0,
        per_page: 0,
        current_page: 1,
        data: allowances,
      }
    },

    async fetchAllowanceTypes() {
      try {
        this.allowanceTypes = await AllowanceTypes.get({
          company_id: this.activeCompany.id,
        })
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    getEmployments() {
      this.reportData.selectedEmployment = this.allOption

      if (this.isAdmin) this.fetchEmployments()
      if (this.isNonAdminApprover) this.getSubordinates()
    },

    async fetchEmployments() {
      try {
        const { data } = await Employments.find({
          company_id: this.activeCompany.id,
          department: this.reportData.selectedDepartment.id,
        })

        this.employments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchAllowances(page) {
      this.fetchingAllowance = true

      try {
        let method =
          this.reportData.reportType === 'detail-report'
            ? 'getDetailsAllowances'
            : 'getSummarisedAllowances'

        this.paginatedAllowances = await AllowanceReport[method]({
          company_id: this.activeCompany.id,
          date: this.reportData.selectedDate.format('YYYY-MM-DD'),
          allowance_type: this.reportData.selectedAllowanceType.id,
          employee: this.reportData.selectedEmployment.id,
          department: this.reportData.selectedDepartment.id,
          page: page || this.paginatedAllowances.current_page,
        })
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.fetchingAllowance = false
    },

    async downloadAllowanceReport() {
      if (!this.companyHasExportToExcelFeature) {
        return
      }

      this.downloadingAllowance = true

      const method =
        this.reportData.reportType === 'detail-report'
          ? 'downloadDetailedAllowanceReport'
          : 'downloadSummarisedAllowanceReport'

      const date = this.reportData.selectedDate.format('YYYY-MM-DD')

      try {
        await AllowanceReport[method](
          {
            date: date,
            company_id: this.activeCompany.id,
            allowance_type: this.reportData.selectedAllowanceType.id,
            employee: this.reportData.selectedEmployment.id,
            department: this.reportData.selectedDepartment.id,
            report_type: this.reportData.reportType,
          },
          `allowance-${this.reportData.reportType}-${date}.xlsx`
        )
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.downloadingAllowance = false
    },
  },
}
</script>

<style>
.paginate-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
