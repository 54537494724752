import http from '@/plugins/http'
import HolidayLocationsCollection from '@/models/company/HolidayLocationsCollection'

export default class CompanyHolidays {
  static async getHolidayLocations(payload) {
    const { data } = await http.get(
      'reports/company-holiday-locations',
      payload
    )

    return new HolidayLocationsCollection(data)
  }

  static async getHolidayNames(params) {
    return await http.get('company-holiday-names', {
      params: params,
    })
  }
}
