<template>
  <div>
    <div
      class="tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-gray-200 tw-p-4 ie_tw-h-screen"
    >
      <div class="tw-w-full w-360">
        <form
          class="tw-bg-white tw-shadow-md tw-rounded tw-px-8 tw-pt-6 tw-pb-8 tw-mb-4"
          data-cy="link-reset-password"
          @submit.prevent="submit"
        >
          <div>
            <HeaderLogo />
          </div>
          <p
            class="tw-font-semibold tw-mb-4 tw-mt-8 tw-text-2xl tw-text-gray-800"
          >
            Reset Password
          </p>
          <div class="tw-mb-4">
            <label class="form-label" for="email">
              Email
            </label>
            <input
              id="email"
              v-model="form.email"
              v-validate="'required|email'"
              v-focus
              name="email"
              class="form-control"
              type="text"
              placeholder="john@example.com"
              autocomplete="off"
              inputmode="email"
              data-cy="email"
              tabindex="1"
            />
            <p
              v-show="errors.has('email')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('email') }}
            </p>
          </div>
          <div class="tw-mb-4">
            <label class="form-label" for="password">
              New Password
            </label>
            <PasswordStrengthMeter
              id="password"
              ref="password"
              v-model="form.password"
              v-validate="'required|min:8|max:128'"
              name="password"
              data-vv-as="new password"
              type="password"
              placeholder="New Password"
              autocomplete="current-password"
              data-cy="password"
              tabindex="2"
            />
            <p
              v-show="errors.has('password')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('password') }}
            </p>
          </div>
          <div class="tw-mb-4">
            <label class="form-label" for="password">
              Confirm New Password
            </label>
            <input
              id="confirm-password"
              v-model="form.password_confirmation"
              v-validate="'required|confirmed:password'"
              name="confirm_password"
              data-vv-as="confirm password"
              class="form-control"
              type="password"
              placeholder="Confirm Password"
              autocomplete="current-password"
              data-cy="confirm-password"
              tabindex="3"
            />
            <p
              v-show="errors.has('confirm_password')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('confirm_password') }}
            </p>
          </div>
          <ReCaptcha ref="recaptcha" :callback="resetPassword"></ReCaptcha>
          <div class="tw-flex tw-items-center tw-justify-between">
            <button
              :disabled="!valid || loading"
              class="btn btn-blue"
              type="submit"
              data-cy="btn-reset-password"
              tabindex="4"
            >
              Reset Password
            </button>
            <RouterLink
              :to="{ name: 'signin' }"
              class="tw-mx-0 btn btn-borderless btn-link btn-link_focus tw-text-blue-500"
              tabindex="5"
            >
              Sign In
            </RouterLink>
          </div>
        </form>

        <AuthFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AuthFooter from '@/components/AuthFooter'
import ValidatesForm from '@/mixins/ValidatesForm'
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter'
import HeaderLogo from '@/components/HeaderLogo'
import Profile from '@/api/profile/Profile'
import ReCaptcha from '@/components/ReCaptcha'

export default {
  name: 'ResetPassword',

  middleware: 'guest',

  layout: 'DefaultLayout',

  components: { ReCaptcha, HeaderLogo, PasswordStrengthMeter, AuthFooter },

  mixins: [ValidatesForm],

  data: () => ({
    form: {
      email: '',
      password: '',
      password_confirmation: '',
    },
    loading: false,
  }),

  mounted() {
    const dictionary = {
      custom: {
        confirm_password: {
          confirmed: 'The password confirmation does not match',
        },
      },
    }

    this.$validator.localize('en', dictionary)
  },

  methods: {
    async resetPassword(recaptchaToken) {
      this.loading = true

      try {
        const { data } = await Profile.resetPassword({
          ...this.form,
          token: this.$route.query.token,
        })

        this.success(data.message)

        await this.login({
          email: this.form.email,
          password: this.form.password,
          'g-recaptcha-response': recaptchaToken,
        })
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },
    async submit() {
      await this.validate()

      if (!this.valid) return

      this.$refs.recaptcha.execute()
    },
  },
}
</script>
