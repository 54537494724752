import http from '@/plugins/http'

export default class Leave {
  static async getReport(params) {
    return await http.get('reports/leave', {
      params: params,
    })
  }

  static async downloadReport(params) {
    return await http.get('reports/downloads/leave', {
      params,
      responseType: 'blob',
    })
  }
}
