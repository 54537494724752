import http from '@/plugins/http'

export default class EmploymentHolidays {
  static async getReport(params) {
    return await http.get('reports/employment-holidays', {
      params: params,
    })
  }

  static async downloadReport(params) {
    return await http.get('reports/downloads/employment-holidays', {
      params: params,
      responseType: 'blob',
    })
  }
}
