import http from '@/plugins/http'

export class Employments {
  static async getReport(params) {
    return await http.get('reports/employments', {
      params: params,
    })
  }

  static async downloadReport(params) {
    return await http.get('reports/downloads/employments', {
      params: params,
      responseType: 'blob',
    })
  }
}
