import gql from 'graphql-tag'

export default gql`
  query EmploymentSchedule(
    $company: String!
    $employment: String!
    $date: Date!
    $interval: Interval!
  ) {
    employmentSchedule(
      company: $company
      employment: $employment
      date: $date
      interval: $interval
    ) {
      id
      dateOfBirth
      leave {
        id
        start: from
        end: to
        status
        type {
          colour
        }
        timezone
      }
      overtime {
        id
        start: from
        end: to
        status
        colour
        timezone
      }
      leaveLimits {
        start
        end
      }
      holidays {
        id
        date
        name
        location
        type
      }
    }
  }
`
